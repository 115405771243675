.navbar {
  background-color: #005493 !important;
  padding: 0px;
  margin: 0px;
}
.navbar-toggler {
  border: none;
  color: #fff !important;
}

.main-wrapper {
  margin-top: 55px;
  height: 100%;
  overflow: hidden;
}

.side-menu .nav-item {
  color: #005493;
  border: 1px solid #6c757d61;
  background-color: #fff;
}

.nav-link {
  color: #005493;
  padding: 4px 20px;
  font-size: 1rem;
}

.header-link {
  color: rgba(255, 255, 255, 0.637);
  padding: 1rem;
}

.header-link:hover {
  color: white;
  opacity: 0.8;
}
.header-link:active {
  color: white;
  font-weight: 600;
}

.main-color {
  color: #005493;
}

.nav-item a:hover {
  background-color: #0054939e;
  color: #fff;
}

.nav-item a.active {
  background-color: #0054939e !important;
  color: #fff !important;
}
.panel-header {
  background-color: #005493 !important;
  border-radius: 10px 10px 0px 0px;
}

.panel-body {
  padding: 20px;
  border-radius: 0px 0px 20px 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}
.panel {
  border: 1px solid #6c757d61;
  border-radius: 10px;
}

.grid-subastas {
  margin: 5px;
}

.card-subasta {
  cursor: pointer;
  margin: 3px !important;
}

.card-subasta .title-card-subasta {
  font-size: 1rem;
  font-weight: 500;
}

.h-80 {
  height: 80px;
}

.icon-whatsapp2 {
  width: 60px;
}
.ico-facebook {
  width: 60px;
}
.ico-instagram2 {
  width: 60px;
}
