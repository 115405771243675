html {
  font-size: 16px;
}

.social-bar {
  position: fixed;
  right: 0;
  top: 35%;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 100;
}

.social-bar a:hover {
  color: white;
}

.icon {
  color: white;
  text-decoration: none;
  padding: 0.7rem;
  display: flex;
  transition: all 0.5s;
}

.icon-facebook2 {
  background: #3b5998;
}

.icon-instagram {
  background: #e1306c;
}

.icon-whatsapp {
  background: #25d366;
}

.icon:first-child {
  border-radius: 1rem 0 0 0;
}

.icon:last-child {
  border-radius: 0 0 0 1rem;
}

.icon:hover {
  padding-right: 3rem;
  border-radius: 1rem 0 0 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.42);
}
