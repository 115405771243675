.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(251, 251, 251, 0.39);
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 30%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-width: 10px;
  border-color: rgba(255, 255, 255, 0.479);
  border-top-color: #065493;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

.input-file input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.cursor-pointer {
  cursor: pointer;
}
.imgContenedor {
  position: relative;
  display: block;
}
.imgContenedor.active img {
  border: 2px dotted #005493;
}

a.imgRemove {
  position: absolute;
  top: 1px;
  right: 5px;
  font-size: 20px;
  color: black;
}
a.imgRemove:hover {
  background-color: #fff;
  border-radius: 50%;
  padding: 0px 4px;
}

a.imgDefault {
  position: absolute;
  top: 1px;
  left: 5px;
  font-size: 20px;
  color: goldenrod;
}
a.imgDefault :hover {
  background-color: #fff;
  border-radius: 50%;
  padding: 0px 4px;
}

.card {
  padding: 0px !important;
  margin: 0px !important;
}

.input-file input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.title-group-form {
  font-weight: 500;
  font-size: 1.25rem;
}

.icon-button {
  padding: 0px 3px;
}

.wrapperClassName {
  border: 1px solid #c0c1c2;
  height: auto;
  overflow: auto;
  min-height: 300px;
}

.modal-form .modal-content {
  background-color: transparent;
}

.modal-form .modal-body {
  padding: 0;
  border: none;
}

.loader-lastlap {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  margin: 15px 50px;
  position: relative;
  background: #fff;
  box-shadow: -30px 0 #fff, 30px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #fff;
    box-shadow: -30px 0 #ffbf00, 30px 0 #fff;
  }
  66% {
    background: #ffbf00;
    box-shadow: -30px 0 #fff, 30px 0 #fff;
  }
  100% {
    background: #fff;
    box-shadow: -30px 0 #fff, 30px 0 #ffbf00;
  }
}

.loader-green {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  margin: 15px 50px;
  position: relative;
  background: #fff;
  box-shadow: -30px 0 #fff, 30px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse2 2s linear infinite;
}

@keyframes shadowPulse2 {
  33% {
    background: #fff;
    box-shadow: -30px 0 green, 30px 0 #fff;
  }
  66% {
    background: green;
    box-shadow: -30px 0 #fff, 30px 0 #fff;
  }
  100% {
    background: #fff;
    box-shadow: -30px 0 #fff, 30px 0 green;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.item-service > h4 {
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}
.item-service > .text-muted {
  text-align: justify;
  padding: 20px;
  font-weight: 400;
  font-size: 1.1rem;
}

.item-service > .fas {
  color: #005493;
}
.subtitle {
  font-weight: 600;
  font-size: 2rem;
}
.subtitle .first {
  padding-top: 40px;
}

.about h3 {
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}
