@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import 'bootstrap/dist/css/bootstrap.min.css'; */
@import "utils/migration.css";
@import "utils/landing.css";
@import "utils/themeTables.css";
@import "utils/themeCategories.css";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import "utils/socialBar/socialBar.css";
@import "utils/socialBar/style.css";